import React from "react"
import styled from "styled-components"
import { standardWrapper } from "../../styles/helpers"
import { Helmet } from "react-helmet"

const DonationPopup = ({ setIsPopupOpen }) => {
  return (
    <DonationPopupStyled>
      <div className="wrapper">
        <Helmet>
          <script
            id="ch_cdn_embed"
            type="text/javascript"
            src="https://www.canadahelps.org/secure/js/cdf_embed.2.js"
            charset="utf-8"
            data-language="en"
            data-page-id="3650"
            data-root-url="https://www.canadahelps.org"
            data-formtype="1"
            data-cfasync="false"
          ></script>
        </Helmet>
        <div>
          <div
            className="popup__content"
            dangerouslySetInnerHTML={{
              __html: `<p><script
            id="ch_cdn_embed"
            type="text/javascript"
            src="https://www.canadahelps.org/secure/js/cdf_embed.2.js"
            charset="utf-8"
            data-language="en"
            data-page-id="3650"
            data-root-url="https://www.canadahelps.org"
            data-formtype="1"
            data-cfasync="false"
          ></script></p>`,
            }}
          />

          <button onClick={() => setIsPopupOpen(false)}>Close</button>
        </div>
      </div>
    </DonationPopupStyled>
  )
}

const DonationPopupStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99999999999;

  .wrapper {
    ${standardWrapper};
  }

  .wysiwyg-embed {
    width: 100%;
    min-height: 10rem;
  }

  .popup__content {
    width: 100%;
    min-height: 10rem;
    background-color: #fff;
  }
`

export default DonationPopup
