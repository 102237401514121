import React, { useEffect } from "react"

import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import HeaderNavItem from "./HeaderNavItem"

const getData = graphql`
  {
    headerMenuBot: wpMenu(name: { eq: "Header Menu Bottom" }) {
      name
      menuItems {
        nodes {
          label
          url
          parentDatabaseId
          parentId
          id
          databaseId
          cssClasses
        }
      }
    }
  }
`

const HeaderMenu = ({ setIsPopupOpen }) => {
  const data = useStaticQuery(getData)
  const { headerMenuBot } = data
  const allNavItems = headerMenuBot?.menuItems?.nodes
  const navItems = allNavItems.filter(item => item.parentDatabaseId === 0)
  const subItems = allNavItems.filter(item => item.parentDatabaseId !== 0)
  const navItemsWithSubs = navItems.map(item => {
    const itemWithSubs = subItems.filter(
      subItem => subItem.parentDatabaseId === item.databaseId
    )
    item.subItems = itemWithSubs
    return item
  })
  const headerNavigation = navItems ? (
    navItemsWithSubs.length > 0 ? (
      <HeaderNavStyled role="navigation" aria-label="primary">
        <ul className="mainNavWrapper">
          {navItemsWithSubs.map(item => {
            return <HeaderNavItem key={item.id} item={item} />
          })}
          {/* <li>
            <button
              // href="#BDA964"
              data-lang="en"
              data-widget-id="0f7b1ade-1a04-4604-9f62-f941e6b49824"
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "5px 10px 5px 5px",
                backgroundColor: "rgb(120, 190, 33)",
                color: "white",
                border: "none",
                textDecoration: "none",
              }}
              onClick={() => setIsPopupOpen(true)}
            >
              <svg id="heart" width="25px" height="20px" viewBox="0 0 512 512">
                <path
                  d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192z"
                  fill="none"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="32"
                ></path>
                <path
                  d="M256 360a16 16 0 0 1-9-2.78c-39.3-26.68-56.32-45-65.7-56.41c-20-24.37-29.58-49.4-29.3-76.5c.31-31.06 25.22-56.33 55.53-56.33c20.4 0 35 10.63 44.1 20.41a6 6 0 0 0 8.72 0c9.11-9.78 23.7-20.41 44.1-20.41c30.31 0 55.22 25.27 55.53 56.33c.28 27.1-9.31 52.13-29.3 76.5c-9.38 11.44-26.4 29.73-65.7 56.41A16 16 0 0 1 256 360z"
                  fill="currentColor"
                ></path>
              </svg>
              Donate
            </button>
          </li> */}
        </ul>
      </HeaderNavStyled>
    ) : null
  ) : null
  return headerNavigation
}

const HeaderNavStyled = styled.nav`
  display: none;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    align-self: center;
    flex-wrap: wrap;
  }

  .mainNavWrapper {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-evenly;
    width: 100%;

    @media (min-width: 1025px) {
      justify-content: space-evenly;
      margin-top: 0;
    }
  }
`

export default HeaderMenu
