import React from "react"
import styled from "styled-components"
import MobileNavItem from "./MobileNavItem"
import { Nav1White, colors } from "../../../styles/helpers"

const MobileNavContainer = ({ navitems }) => {
  const topNavItems = navitems.filter(item => item.parentDatabaseId === 0)
  const subNavItems = navitems.filter(item => item.parentDatabaseId !== 0)
  const navItemsWithSubs = topNavItems.map(item => {
    const itemWithSubs = subNavItems.filter(
      subItem => subItem.parentDatabaseId === item.databaseId
    )
    item.subItems = itemWithSubs
    return item
  })

  return (
    <MobileNavContainerStyled>
      <ul>
        {/* <li>
          <a
            href="#BDA964"
            data-lang="en"
            data-widget-id="0f7b1ade-1a04-4604-9f62-f941e6b49824"
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "5px 10px 5px 5px",
              backgroundColor: "rgb(120, 190, 33)",
              color: "white",
              border: "none",
              textDecoration: "none",
            }}
          >
            <svg id="heart" width="25px" height="20px" viewBox="0 0 512 512">
              <path
                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="32"
              ></path>
              <path
                d="M256 360a16 16 0 0 1-9-2.78c-39.3-26.68-56.32-45-65.7-56.41c-20-24.37-29.58-49.4-29.3-76.5c.31-31.06 25.22-56.33 55.53-56.33c20.4 0 35 10.63 44.1 20.41a6 6 0 0 0 8.72 0c9.11-9.78 23.7-20.41 44.1-20.41c30.31 0 55.22 25.27 55.53 56.33c.28 27.1-9.31 52.13-29.3 76.5c-9.38 11.44-26.4 29.73-65.7 56.41A16 16 0 0 1 256 360z"
                fill="currentColor"
              ></path>
            </svg>
            Donate
          </a>
        </li> */}
        {navItemsWithSubs.map(item => (
          <MobileNavItem key={item.id} item={item} />
        ))}
      </ul>
    </MobileNavContainerStyled>
  )
}

const MobileNavContainerStyled = styled.nav`
  display: block;
  width: 100%;
  padding-bottom: 5rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .logoutBtn {
      position: relative;
      width: 100%;
      border-bottom: 0.1rem solid ${colors.white};
      text-align: center;

      button,
      a {
        ${Nav1White};
        display: block;
        width: 100%;
        padding: 2rem;
        border: none;
        background-color: transparent;
        text-transform: uppercase;

        &:hover {
          color: ${colors.colorTertiary};
        }
      }
    }
  }
`

export default MobileNavContainer
