import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import MainLogo from "./Logos/MainLogo"
import HeaderNav from "./Navigation/HeaderNav/HeaderNav"
import MobileNav from "./Navigation/MobileNav/MobileNav"
import { medWrapper } from "../styles/helpers"
import Search from "./Search"

const isBrowser = () => typeof window !== "undefined"

const Header = ({ siteTitle, setIsPopupOpen, posts, engine }) => {
  // useEffect(() => {
  //   if (isBrowser()) {
  //     if (document.querySelector("#CH-modal")) return
  //     const script = document.createElement("script")
  //     script.type = "text/javascript"
  //     script.src = "https://www.canadahelps.org/secure/js/widget_form.js"
  //     document.body.appendChild(script)
  //   }
  // }, [])
  return (
    <HeaderStyled role="banner" className="site-header">
      <div className="headerLogo">
        <h1>
          <Link to="/">
            <MainLogo /> <span>{siteTitle}</span>
          </Link>
        </h1>
      </div>
      <div>
        <Search posts={posts} engine={engine} />
      </div>
      <div className="mainNav">
        <HeaderNav setIsPopupOpen={setIsPopupOpen} />
      </div>
      <MobileNav setIsPopupOpen={setIsPopupOpen} />
    </HeaderStyled>
  )
}

const HeaderStyled = styled.header`
  position: relative;
  z-index: 500;

  .wrapper {
    ${medWrapper};
    margin-top: 0.15rem;
    padding-top: 0;
    margin-bottom: 0.15rem;
    padding-bottom: 0;
    padding: 0;
    max-width: 100% !important;
  }

  .headerLogo {
    align-self: center;
    margin: auto;
    width: 100%;
    text-align: center;

    @media (min-width: 825px) {
      width: calc(100%);
      margin-bottom: -7.13rem;
    }

    @media (min-width: 1025px) {
      width: calc(100%);
      margin-bottom: -7.13rem;
    }

    a {
      display: block;
      margin: auto;

      &:focus {
        outline: 0.4rem solid #003b49;
        transition: outline-width 0.35s ease-in-out;
      }
    }

    h1 {
      width: 100%;
      max-width: 30rem;
      margin: auto;

      span {
        position: absolute;
        left: -999%;
      }

      @media (min-width: 768px) {
        width: calc(100%);
      }

      @media (min-width: 1025px) {
        width: calc(100% - 10rem);
      }
    }
  }

  .mainNav {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(100%);
    }

    @media (min-width: 1025px) {
      width: calc(100%);
    }
  }
`

export default Header
