import React, { useState } from "react"
import { ThemeProvider } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import theme from "../styles/theme/Theme"
import GlobalStyle from "../styles/global/Golbal"
import Header from "./Header"
import Footer from "./Footer"
import DonationPopup from "./Modals/DonationPopup"
import HeaderNotFound from "./HeaderNotFound"

const Layout = ({ children, posts, engine, pageNotFound }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {!pageNotFound ? (
          <Header
            siteTitle={data.site.siteMetadata?.title || `Title`}
            setIsPopupOpen={setIsPopupOpen}
            posts={posts}
            engine={engine}
          />
        ) : (
          <HeaderNotFound
            siteTitle={data.site.siteMetadata?.title || `Title`}
            setIsPopupOpen={setIsPopupOpen}
          />
        )}
        <main id="main" role="main">
          {children}
        </main>
        <Footer />
        {isPopupOpen && <DonationPopup setIsPopupOpen={setIsPopupOpen} />}
      </ThemeProvider>
    </>
  )
}

export default Layout
