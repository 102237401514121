const themeColors = {
  colorPrimary: "#663a87",
  colorSecondary: "#74bf44",
  colorTertiary: "#e5ede7",
  colorAccent: "#00625d",
  colorShad: "#4d5871",
  colorAlt: "#cecdda",
  white: "rgba(245,245,245, 1)",
  grey: "rgba(226,232,233, 1)",
  greyMed: "rgba(120,119,124, 1)",
  greyBrownish: "#707070",
  greyBrown: "rgba(67,67,67, 1)",
  black: "rgba(0,0,0, 1)",
  strongred: "rgba(255, 0, 0, 1)",
}

export default themeColors
