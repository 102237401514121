import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import {
  colors,
  Nav1White,
  fontSizer,
  H4White,
  medWrapper,
} from "../styles/helpers"
import SocialMedia from "./Footer/SocialMedia"

const getData = graphql`
  {
    quickLinks: wpMenu(name: { eq: "Quick Links Menu" }) {
      name
      menuItems {
        nodes {
          label
          url
          parentDatabaseId
          parentId
          id
          databaseId
        }
      }
    }

    footerdata: wp {
      acfOptionsSiteWideSettings {
        acfSiteWideSettings {
          addressDirections
          mailchimpContent
          charitableRegistrationNumber
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(getData)
  const { quickLinks, footerdata } = data
  return (
    <FooterStyled>
      <div className="wrapper">
        <div className="footerLinks">
          <div className="footerLinks__social">
            <div className="footerLinks__social--nav">
              <h3>Follow Us</h3>
            </div>
            <div className="footerLinks__social--links">
              <SocialMedia />
            </div>
          </div>
          <div className="footerLinks__quick">
            <div className="footerLinks__quick--nav">
              <nav>
                <ul>
                  {quickLinks.menuItems.nodes.map(item => {
                    const slug = item.url
                      .split("/")
                      .filter(item => item !== "")
                      .join("/")
                    return (
                      <li key={item.id}>
                        <Link to={`/${slug}`}>{item.label}</Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>

          <div className="footerLinks__address">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  footerdata.acfOptionsSiteWideSettings.acfSiteWideSettings
                    .addressDirections,
              }}
            />
          </div>

          <div className="footerLinks__mailchimp">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  footerdata.acfOptionsSiteWideSettings.acfSiteWideSettings
                    .mailchimpContent,
              }}
            />
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://arbi.us13.list-manage.com/subscribe?u=3fb293250f141a7c2aa6f306a&id=20b361b910"
              >
                Subscribe here
              </a>
            </div>
          </div>

          <div className="footerLinks__copy">
            <div className="footerLinks__copy--crn">
              <p>
                Charitable Registration Number:{" "}
                {
                  footerdata.acfOptionsSiteWideSettings.acfSiteWideSettings
                    .charitableRegistrationNumber
                }
              </p>
            </div>
            <div className="footerLinks__copy--meta">
              <p>
                ARBI© {new Date().getFullYear()} All Rights Reserved. Designed,
                and developed by{" "}
                <a
                  title="Switchback Creative - Link will open in new window"
                  target="_blank"
                  rel="noreferrer"
                  href="https://switchbackcreative.ca"
                >
                  Switchback Creative
                </a>
                , Built with ♡ and
                {` `}
                <a
                  title="Gatsby JS - Link will open in new window"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.gatsbyjs.com"
                >
                  Gatsby
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </FooterStyled>
  )
}

const FooterStyled = styled.footer`
  background-color: #4d5871;
  padding-top: 2.5rem;

  .wrapper {
    ${medWrapper};
  }

  .footerLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;

    h3 {
      ${H4White};
      padding-bottom: 0.5rem;
      font-weight: normal;
      text-transform: uppercase;
    }

    &__social,
    &__address,
    &__mailchimp,
    &__quick {
      width: 100%;
      margin: 2.5rem auto;

      @media (min-width: 768px) {
        width: calc(25% - 1rem);
        margin: 0 0.5rem;
      }

      p {
        ${Nav1White};

        &:hover {
          color: ${colors.white};
          cursor: inherit;
        }
      }

      a {
        ${Nav1White};
        text-transform: uppercase;

        &:hover {
          color: ${colors.colorSecondary};
        }
      }
    }

    &__quick {
      &--nav {
        width: 100%;

        li {
          a {
            ${Nav1White};
            text-transform: uppercase;

            &:hover {
              color: ${colors.colorSecondary};
            }
          }
        }
      }
    }

    &__copy {
      width: 100%;
      padding-top: 2.5rem;
      text-align: center;

      @media (min-width: 768px) {
        padding-top: 5rem;
      }
      &--crn,
      &--meta {
        margin-top: 0.75rem;

        p {
          ${Nav1White};
          ${fontSizer(1.2, 1.2, 76.8, 150, 1.4)};
          margin: 0;
          line-height: 1.45;

          &:hover {
            color: ${colors.white};
            cursor: initial;
          }

          a {
            ${Nav1White};
            ${fontSizer(1.2, 1.2, 76.8, 150, 1.4)};
          }
        }
      }
    }
  }
`

export default Footer
