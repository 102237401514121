import React, { useEffect } from "react"
import { isEmpty } from "lodash"
import Link from "gatsby-link"
import { sanitize } from "../utils/functions"
import styled from "styled-components"
import { B1Slate, colors, fonts } from "../styles/helpers"

const SearchResults = ({
  queryResults,
  current,
  setCurrent,
  items,
  setItems,
}) => {
  useEffect(() => {
    setItems([...document.querySelectorAll(".search-results li")])
    setCurrent(null)
  }, [queryResults])

  const dropDownScrollDown = num => {
    if (items.length - 1 !== num) {
      items.forEach(item => item.classList.remove("active"))
    }

    if (num === null) {
      items[0].classList.add("active")
      items[0].querySelector("a").focus()
      setCurrent(0)
      return
    }

    if (items.length - 1 > num) {
      setCurrent(prevState => prevState + 1)
      items[num + 1].classList.add("active")
      items[num + 1].querySelector("a").focus()
      return
    }
  }

  const dropDownScrollup = num => {
    if (num !== 0) {
      items.forEach(item => item.classList.remove("active"))
    }

    if (num === null) {
      items[items.length - 1].classList.add("active")
      items[items.length - 1].querySelector("a").focus()
      setCurrent(items.length - 1)
      return
    }

    if (num !== 0) {
      setCurrent(prevState => prevState - 1)
      items[num - 1].classList.add("active")
      items[num - 1].querySelector("a").focus()
      return
    }
  }

  useEffect(() => {
    document.onkeydown = function (event) {
      switch (event.keyCode) {
        case 38:
          event.preventDefault()
          dropDownScrollup(current)
          break
        case 40:
          event.preventDefault()
          dropDownScrollDown(current)
          break
      }
    }
  }, [items, current])

  if (isEmpty(queryResults)) {
    return null
  }

  const handleOnBlur = evt => {
    if (evt.target.parentElement.classList.contains("active")) {
      evt.target.parentElement.parentElement.classList.add("hidden")
      console.log(
        "HIDE THE DROPDOWN!!!",
        evt.target.parentElement.parentElement
      )
    }
  }

  return (
    <StyledUl className="search-results" onBlur={handleOnBlur}>
      {queryResults.map((post, index) => {
        if (post.isPost) {
          return (
            <li tabIndex={index} key={post.id}>
              <Link to={`/news-and-events${post.uri}`}>
                <span
                  dangerouslySetInnerHTML={{ __html: sanitize(post.title) }}
                />
              </Link>
            </li>
          )
        } else {
          return (
            <li tabIndex={index} key={post.id}>
              <Link to={post.uri}>
                <span
                  dangerouslySetInnerHTML={{ __html: sanitize(post.title) }}
                />
              </Link>
            </li>
          )
        }
      })}
    </StyledUl>
  )
}

const StyledUl = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 30rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border: solid 0.1rem #000;
  overflow-y: scroll;
  z-index: 50000;

  &.hidden {
    display: none;
  }

  li {
    ${B1Slate};
    position: relative;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;

    &::before {
      font-family: ${fonts.fontAwesome};
      position: absolute;
      top: 0em;
      left: 0.5rem;
      padding-right: 0.5em;
      color: rgba($grey, 0.75);
      content: "\f0da";
    }

    &.active {
      background-color: ${colors.colorPrimary};

      &::before {
        color: #fff;
      }

      a {
        color: #fff;
      }
    }

    a {
      ${B1Slate};

      &:hover {
        color: ${colors.colorSecondary};
      }

      &:focus {
        outline: none;
      }
    }
  }
`

export default SearchResults
