import React, { useState } from "react"
import SearchResults from "./SearchResults"
import styled from "styled-components"
import { B1Slate, B1White, colors } from "../styles/helpers"

const SearchForm = ({
  handleSubmit,
  searchData,
  searchQuery,
  placeholder,
  queryResults,
}) => {
  const [current, setCurrent] = useState(null)
  const [items, setItems] = useState([])

  const resetTheIndex = () => {
    const listContainer = document.querySelector(".search-results.hidden")

    if (listContainer) {
      listContainer.classList.remove("hidden")
    }

    items.forEach(item => item.classList.remove("active"))
    setCurrent(null)
  }

  return (
    <div>
      <StyledForm className="search-form" onSubmit={handleSubmit}>
        <label htmlFor="Search" className="screen-reader-text">
          Search:
        </label>
        <input
          id="Search"
          className="search-input"
          value={searchQuery}
          onChange={searchData}
          placeholder={placeholder}
          autoComplete="off"
          onFocus={resetTheIndex}
        />
        <SearchResults
          queryResults={queryResults}
          current={current}
          setCurrent={setCurrent}
          items={items}
          setItems={setItems}
        />
      </StyledForm>
    </div>
  )
}

const StyledForm = styled.form`
  position: relative;
  label {
    ${B1Slate};
    margin-right: 0.5rem;
  }

  input {
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    color: #444;
    margin-left: 0;
    margin-right: 0;
    border: 0.1rem ${colors.colorSecondary} solid;
  }
`

export default SearchForm
