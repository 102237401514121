import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import {
  colors,
  Nav1White,
  Nav1Slate,
  Btn1Green,
} from "../../../styles/helpers"

import HeaderSubMenu from "./HeaderSubMenu"

const isBrowser = () => typeof window !== "undefined"

const HeaderNavItem = ({ item, top }) => {
  const slug = item.url
    .split("/")
    .filter(item => item !== "")
    .join("/")
  const [subActive, setSubActive] = useState(false)

  const handleIsActiveOn = () => {
    setSubActive(true)
  }

  const handleIsActiveOff = () => {
    setSubActive(false)
  }

  const needsRefresh = slug === "donate"

  console.log("item", item.cssClasses.length > 0)

  return (
    <HeaderNavItemStyled
      top={top}
      className={
        item.cssClasses.length > 0 ? item.cssClasses.map(item => item) : ""
      }
    >
      {needsRefresh && isBrowser() ? (
        <button
          onClick={() =>
            window.location.replace(`${process.env.GATSBY_BASE_URL}${item.url}`)
          }
          onMouseEnter={handleIsActiveOn}
          onMouseLeave={handleIsActiveOff}
          onFocus={handleIsActiveOn}
        >
          {item.label}
        </button>
      ) : (
        <Link
          to={`/${slug}`}
          onMouseEnter={handleIsActiveOn}
          onMouseLeave={handleIsActiveOff}
          onFocus={handleIsActiveOn}
        >
          {item.label}
        </Link>
      )}
      {item.subItems.length > 0 && (
        <>
          <span className="subIndicator">&#x25BC;</span>
          <HeaderSubMenu
            handleIsActiveOn={handleIsActiveOn}
            handleIsActiveOff={handleIsActiveOff}
            handleIsActiveOffBlur={handleIsActiveOff}
            subActive={subActive}
            items={item.subItems}
          />
        </>
      )}
    </HeaderNavItemStyled>
  )
}

const HeaderNavItemStyled = styled.li`
  flex-grow: 1;
  padding: 0 1rem;
  position: relative;
  align-self: center;
  text-align: center;

  a,
  button {
    ${props => (props.top ? Nav1Slate : Nav1White)}
    margin: 0 auto;
    border: none;
    border-top: 0.2rem solid ${colors.colorSecondary};
    border-bottom: 0.2rem solid ${colors.colorSecondary};
    border-color: transparent;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.35s ease-in-out;

    &:hover {
      border-color: ${colors.colorSecondary};
      color: ${props => (props.top ? colors.colorAccent : colors.white)};
    }

    &[aria-current="page"] {
      color: ${props => (props.top ? colors.colorAccent : colors.white)};
      border-color: ${colors.colorSecondary};

      &:hover {
        color: ${props => (props.top ? colors.colorAccent : colors.white)};
        cursor: default;
      }
    }

    &:focus {
      outline: 0.4rem solid #74bf44;
      transition: all 0.35s ease-in-out;
    }
  }

  button {
    background-color: transparent;
  }

  .subIndicator {
    display: inline-block;
    color: ${colors.white};
    font-size: 1rem;
    padding-left: 0.5rem;
  }

  &.special {
    a,
    button {
      ${Btn1Green};
      padding: 0rem 1.5rem;
    }
  }
`

export default HeaderNavItem
